export const masking = (str: string, leftPad = 0, rightPad?: number): string =>
  str.slice(0, leftPad).padEnd(str.length, `*`) +
  (rightPad ? str.slice(str.length - rightPad) : ``);

export const stripTrailingSlash = (str: string): string =>
  str !== `/` && str.endsWith(`/`) ? str.slice(0, -1) : str;

export const getFullBirthday = (str: string): string =>
  str.substring(6, 7) === '1' ||
  str.substring(6, 7) === '2' ||
  str.substring(6, 7) === '5' ||
  str.substring(6, 7) === '6'
    ? `19${str.substring(0, 6)}`
    : `20${str.substring(0, 6)}`;

export const convertJuminnoToGender = (juminno: string) => {
  const genM = ['1', '3', '5', '7', '9'];
  const genF = ['0', '2', '4', '6', '8'];
  if (!juminno || juminno.length !== 13) return '';
  if (genM.includes(juminno.slice(6, 7)))
    return 'M';
  if (genF.includes(juminno.slice(6, 7)))
    return 'F';
  return '';
}